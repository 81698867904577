import React from "react";
import parse from "html-react-parser";

import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";
import BenefitsCard from "/src/components/cards/BenefitsCard";

import {LottieAnimation} from "../../styled/lotti/LottieAnimations";
import Col from "../../styled/grid/Col";
import {Body, Header2, Span, Title} from "../../styled/typography/Typography";
import {ButtonA, ButtonContainer, ButtonLink} from "../../styled/button/ButtonComponents";
import {OnlyDesktop, OnlyMobile} from "../../styled/views/MobileOrDesktop";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const OtherProductsHeader = ({
                                 title_white,
                                 title_color,
                                 brand_color,
                                 subtitle,
                                 description,
                                 url,
                                 url_download,
                                 benefits_card,
                                 noAnim,
                                 invertLotti,
                                 aboutStyle
                             }) => {

    return (
        <>
            {!noAnim && <LottieAnimation animationData={circuit} left={!invertLotti ? "true" : ""}
                                         turned={invertLotti ? "true" : ""}/>}
            <SectionLayoutGrid id={title_color ? title_color : title_white} customBackground="middle">
                <Col span={6} center>
                    <Title color="white" marginBottom="normal">
                        {title_white}
                        {title_color && <><br/><Span color={brand_color}>{title_color}</Span></>}
                    </Title>
                    {benefits_card &&
                    <Col>
                        <OnlyMobile>
                            {benefits_card.map((item) => (
                                <BenefitsCard key={item.id} title={item.title} isCircular={true}/>))}
                        </OnlyMobile>
                    </Col>
                    }
                    {subtitle &&
                    <Header2 color="white" marginBottom="small" small>{subtitle}</Header2>
                    }
                    {description && <Body color="body-text">{parse(description)}</Body>}
                    {url && !aboutStyle &&
                    <ButtonContainer>
                        {url[0] && <ButtonLink to={url[0].link} color={brand_color}>{url[0].name}</ButtonLink>}
                        {url[1] &&
                        <ButtonLink to={url[1].link} color="secondary-brand">{url[1].name}</ButtonLink>}
                    </ButtonContainer>
                    }
                    {url && url_download && aboutStyle &&
                    <ButtonContainer>
                        <ButtonLink to={url.link} color={brand_color}>{url.name}</ButtonLink>
                        <ButtonA href={url_download.file.url} target="_blank" rel="noreferrer"
                                 color="secondary-brand">{url_download.name}</ButtonA>
                    </ButtonContainer>
                    }
                </Col>
                {benefits_card &&
                <Col span={6} grid verticalcenter>
                    <OnlyDesktop>
                        {benefits_card.map((item) => (
                            <BenefitsCard key={item.id} title={item.title} isCircular/>))}
                    </OnlyDesktop>
                </Col>
                }
            </SectionLayoutGrid>
        </>
    )
}

export default OtherProductsHeader