import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Gallery from "/src/components/pages/about/Gallery";
import OtherProductsHeader from "/src/components/pages/products/OtherProductsHeader";

const AboutUs = ({data}) => {
    
    const {about} = data
    
        return (
            <Layout lang={about.lang} seo={about.SEO}>
                <OtherProductsHeader
                    title_white={about.about.title}
                    description={about.about.description}
                    url={about.about.button}
                    url_download={about.about.button_download}
                    benefits_card={about.about.cards}
                    brand_color="primary-brand"
                    aboutStyle
                />
                <Gallery title={about.gallery_title} images={about.gallery_images}/>
            </Layout>
        )
}

export const query = graphql`
   query GetSingleAbout($locale: String) {
         about: strapiAboutBalasys(lang: { eq: $locale }) {
          lang
          about {
            title
            description
            button {
              id
              link
              name
            }
            button_download {
              file {
                url
              }
              name
            }
            cards {
              id
              title
            }
          }
          gallery_title
          gallery_images {
            id
            url
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default AboutUs